import { Box, Typography } from "@mui/material";
import AppContainer from "../../components/Layout/AppContainer/AppContainer";
import EventItem from "../../components/EventItem/EventItem";
import DateRangePicker from "../../components/Forms/DateRangePicker/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import {
  ROUTE_NAME,
  RoutePath,
  TIMEZONE_SHORTLABEL,
} from "../../utils/constant";
import SubMenuHeader from "../../components/Layout/SubMenuHeader/SubMenuHeader";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UpcomingScheduleQuery, UpcomingScheduleStatus } from "../../api/request.types";
import { useMemo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootReducerState } from "../../redux/reducers";
import { useGetUpcomingSchedules } from "../../query/queries";

const EventPage = () => {
  const userReducer = useSelector((state: RootReducerState) => state.userReducer);
  const companyId = userReducer.data.companyId || "";
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const getValidDateRange = (start: string | null, end: string | null) => {
    const today = dayjs();
    const defaultStartDate = today;
    const defaultEndDate = today.add(7, "day");

    const startDate = start ? dayjs(start, "YYYY-MM-DD") : defaultStartDate;
    let endDate = end ? dayjs(end, "YYYY-MM-DD") : defaultEndDate;

    if (endDate.isBefore(startDate)) {
      endDate = startDate.add(7, "day");
    }

    return { startDate, endDate };
  };

  const { startDate, endDate } = getValidDateRange(
    searchParams.get("startDate"),
    searchParams.get("endDate")
  );

  const [dateRange, setDateRange] = useState<{ startDate: Dayjs; endDate: Dayjs }>({
    startDate,
    endDate,
  });

  useEffect(() => {
    setSearchParams({
      startDate: dateRange.startDate.format("YYYY-MM-DD"),
      endDate: dateRange.endDate.format("YYYY-MM-DD"),
    }, { replace: true });
  }, [dateRange, setSearchParams]);

  const upcomingSchedulesQuery: UpcomingScheduleQuery = useMemo(
    () => ({
      startDate: dateRange.startDate.format("YYYY-MM-DD"),
      endDate: dateRange.endDate.format("YYYY-MM-DD"),
      companyID: companyId,
    }),
    [dateRange, companyId]
  );

  const getUpcomingSchedulesQuery = useGetUpcomingSchedules(upcomingSchedulesQuery);
  const upcomingSchedules = useMemo(() => {
    return getUpcomingSchedulesQuery.data?.data?.data;
  }, [getUpcomingSchedulesQuery.data?.data?.data]);

  return (
    <AppContainer>
      <SubMenuHeader
        leftNav={{
          icon: <FaArrowLeft />,
          onClick: () => {
            navigate(-1);
          },
        }}
        text={"List Reservasi"}
      />
      <Box display="flex" flexDirection="column" gap={1} px={2} pt={1} pb={1}>
        <Typography variant="body1" fontWeight={600}>
          Jadwal Akan Datang
        </Typography>
        <DateRangePicker
          startDate={dateRange.startDate}
          endDate={dateRange.endDate}
          onChangeStartDate={(date) => {
            setDateRange((prev) => ({
              ...prev,
              startDate: date,
              endDate: dateRange.endDate.isBefore(date) ? date : prev.endDate
            }));
          }}
          onChangeEndDate={(date) => {
            setDateRange((prev) => ({
              ...prev,
              endDate: date,
            }));
          }}
        />
      </Box>
      <Box display="flex" flexDirection="column" px={2} pt={2} pb={5}>
        {!upcomingSchedules?.length ? (
          <Typography>Belum ada jadwal</Typography>
        ) : (
          (upcomingSchedules || []).map((schedule) => {
            return (
              <EventItem
                key={schedule.transactionID}
                status={
                  schedule.status === UpcomingScheduleStatus.ENDED
                    ? "done"
                    : schedule.status
                }
                date={dayjs(schedule.date)}
                productName={schedule.productName}
                startTime={schedule.startTime}
                endTime={schedule.endTime}
                timezone={TIMEZONE_SHORTLABEL[schedule.timezone]}
                qty={schedule.qty}
                onClick={() => {
                  navigate(`${RoutePath[ROUTE_NAME.TRANSACTION]}/${schedule.transactionID}`);
                }}
              />
            );
          })
        )}
      </Box>
    </AppContainer>
  );
};

export default EventPage;
