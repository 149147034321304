import { Box, CircularProgress } from "@mui/material";
import { useMemo } from "react";
import { useDynamicPage } from "../../NavigateOnValidCompanyName";
import { useGetPublicCompanyDomainV2 } from "../../../query/queries";
import TemplateHome from "../../../components/TemplateHome/TemplateHome";

const D2Home = () => {
  const FONT_COLOR = "black";
  const { subdomain } = useDynamicPage();

  const companyV2Query = useGetPublicCompanyDomainV2(subdomain || "");
  const companyV2Detail = useMemo(() => {
    return companyV2Query.data?.data?.data;
  }, [companyV2Query.data?.data?.data]);

  if (companyV2Query.isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }
  return (
    <TemplateHome
      mode="default"
      fontColor={FONT_COLOR}
      color={companyV2Detail?.color}
      logoSignedURL={companyV2Detail?.logoSignedURL}
      name={companyV2Detail?.name}
      description={companyV2Detail?.description}
      socialMedia={companyV2Detail?.socialMedia || {}}
      productDetails={companyV2Detail?.productDetails || []}
      currency={companyV2Detail?.currency}
    />
  );
};

export default D2Home;
