import {useEffect, useRef, useState} from "react";
import ENV from "../utils/env";

const useSnap = () => {
  const snapRef = useRef();
  const [snap, setSnap] = useState(null);
  const initSnap = () => {
    return new Promise(resolve => {
      const midtransClientKey = ENV.MIDTRANS_CLIENT_KEY;
      const script = document.createElement("script");
      script.src = `${ENV.MIDTRANS_API_URL}/snap/snap.js`;
      script.setAttribute("data-client-key", midtransClientKey);
      script.onload = () => {
        setSnap(window.snap);
        snapRef.current = window.snap;
        resolve();
      };
      document.body.append(script);
    })

  }
  useEffect(() => {
    if (!snap) {
      initSnap();
    }
    return () => {
      const scriptNode = document.getElementById("script");
      if (scriptNode) {
        document.body.removeChild(scriptNode);
      }
    };
  }, [snap]);

  const snapEmbed = (snap_token, embedId, action) => {
    if (snapRef.current) {
      snapRef.current.embed(snap_token, {
        embedId,
        onSuccess: (result) => {
          action.onSuccess(result);
        },
        onPending: (result) => {
          action.onPending(result);
        },
        onClose: () => {
          action.onClose();
        },
      });
    } else {
      console.error("Snap is not loaded yet.");
    }
  };

  return {snapEmbed, initSnap, snap};
};

export default useSnap;
