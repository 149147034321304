import React, { useMemo, useState } from "react";
import { Box, Skeleton, Typography } from "@mui/material";
import MobileContainer from "../../components/Layout/MobileContainer/MobileContainer";
import { COLOR } from "../../utils/color";
import { FaWallet } from "react-icons/fa";
import { formatCurrency } from "../../utils/helper";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts";
import {
  useGetCompanyByIdV2,
  useGetProductByTransaction,
  useGetTotalTransactionPerDate,
  useGetTransactionByStatus,
  useGetTransactionOverview,
  useGetUpcomingSchedules,
} from "../../query/queries";
import {
  DashboardBaseQuery,
  UpcomingScheduleQuery,
  UpcomingScheduleStatus,
} from "../../api/request.types";
import { RootReducerState } from "../../redux/reducers";
import { useSelector } from "react-redux";
import DateRangePicker from "../../components/Forms/DateRangePicker/DateRangePicker";
import dayjs, { Dayjs } from "dayjs";
import EventItem from "../../components/EventItem/EventItem";
import OpacityButton from "../../components/OpacityButton/OpacityButton";
import {
  ROUTE_NAME,
  RoutePath,
  TIMEZONE_SHORTLABEL,
  TIMEZONES,
} from "../../utils/constant";
import { useNavigate } from "react-router-dom";

const Home: React.FC = () => {
  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );
  const companyId = userReducer.data.companyId || "";
  const navigate = useNavigate();
  const today = dayjs();
  const [dateRange, setDateRange] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>({ startDate: dayjs().startOf("weeks"), endDate: dayjs().endOf("weeks") });

  const getCompanyById = useGetCompanyByIdV2(companyId, {
    enabled: true,
  });
  const companyDetail = getCompanyById.data?.data.data;

  const dashboardQuery: DashboardBaseQuery = useMemo(
    () => ({
      startDate: dateRange.startDate.format("YYYY-MM-DD"),
      endDate: dateRange.endDate.format("YYYY-MM-DD"),
      companyID: companyId,
      sort: "desc",
    }),
    [dateRange]
  );

  const upcomingSchedulesQuery: UpcomingScheduleQuery = useMemo(
    () => ({
      startDate: today.format("YYYY-MM-DD"),
      companyID: companyId,
      limit: 3,
    }),
    [dateRange]
  );
  const totalTransactionPerDateQuery =
    useGetTotalTransactionPerDate(dashboardQuery);
  const totalTransactionPerDate = useMemo(() => {
    return totalTransactionPerDateQuery.data?.data?.data;
  }, [totalTransactionPerDateQuery.data?.data?.data]);

  const totalTransactionByStatusQuery =
    useGetTransactionByStatus(dashboardQuery);
  const totalTransactionByStatus = useMemo(() => {
    return totalTransactionByStatusQuery.data?.data?.data;
  }, [totalTransactionByStatusQuery.data?.data?.data]);

  const productByTransactionQuery = useGetProductByTransaction(dashboardQuery);
  const productByTransaction = useMemo(() => {
    return productByTransactionQuery.data?.data?.data;
  }, [productByTransactionQuery.data?.data?.data]);

  const transactionOverviewQuery = useGetTransactionOverview(dashboardQuery);
  const transactionOverview = useMemo(() => {
    return transactionOverviewQuery.data?.data?.data;
  }, [transactionOverviewQuery.data?.data?.data]);

  const getUpcomingSchedulesQuery = useGetUpcomingSchedules(
    upcomingSchedulesQuery
  );
  const upcomingSchedules = useMemo(() => {
    return getUpcomingSchedulesQuery.data?.data?.data;
  }, [getUpcomingSchedulesQuery.data?.data?.data]);

  const dataset = () => {
    const dateData = [];
    for (
      let startDate = dayjs(dashboardQuery.startDate);
      startDate.isSame(dashboardQuery.endDate) ||
      startDate.isBefore(dashboardQuery.endDate);
      startDate = startDate.add(1, "days")
    ) {
      dateData.push({
        day: startDate.format("YYYY-MM-DD"),
        transactionCount: 0,
      });
    }
    return dateData;
  };

  const datasetPie = [
    { label: "Berhasil (0)", value: 0, color: COLOR.success500 },
    { label: "Gagal (0)", value: 0, color: COLOR.danger500 },
    { label: "Pending (0)", value: 0, color: COLOR.neutral500 },
  ];

  const _renderLoading = () =>
    Array.from({ length: 10 }).map((_, idx) => (
      <Skeleton key={idx} height="68px" sx={{ transform: "none" }} />
    ));

  const _renderProductList = () =>
    productByTransaction?.map((product) => (
      <Box
        key={product.productID}
        display="flex"
        flexDirection="row"
        border={`1px solid ${COLOR.neutral300}`}
        justifyContent="space-between"
        alignItems="center"
        borderRadius={2}
        p={1}
      >
        <Box display="flex" flexDirection="column">
          <Typography
            fontWeight={500}
            color={COLOR.neutral500}
            variant="caption"
          >
            ID: {product.productID}
          </Typography>
          <Typography fontWeight={600}>{product.productName}</Typography>
          <Typography
            style={{
              display: "flex",
              flexDirection: "row",
            }}
            fontWeight={400}
          >
            {"Total Transaksi Berhasil:"}
            &nbsp;
            <Typography fontWeight={600}>{product.total}</Typography>
          </Typography>
        </Box>
      </Box>
    )) || [];

  return (
    <MobileContainer>
      <Box display="flex" flexDirection="column" height="100%" px={2} py={5}>
        <Box>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1" fontWeight={600} mb={1}>
              Jadwal Akan Datang
            </Typography>
            <OpacityButton
              onClick={() => {
                navigate(RoutePath[ROUTE_NAME.EVENTS]);
              }}
            >
              <Typography variant="caption" color={COLOR.neutral500}>
                Lihat Semua
              </Typography>
            </OpacityButton>
          </Box>
          <Box display="flex" flexDirection="column" gap={1}>
            {!upcomingSchedules?.length ? (
              <Typography>Belum ada jadwal</Typography>
            ) : (
              (upcomingSchedules || []).map((schedule) => {
                return (
                  <EventItem
                    status={
                      schedule.status === UpcomingScheduleStatus.ENDED
                        ? "done"
                        : schedule.status
                    }
                    date={dayjs(schedule.date)}
                    productName={schedule.productName}
                    startTime={schedule.startTime}
                    endTime={schedule.endTime}
                    timezone={TIMEZONE_SHORTLABEL[schedule.timezone]}
                    qty={schedule.qty}
                    onClick={() => {
                      navigate(
                        `${RoutePath[ROUTE_NAME.TRANSACTION]}/${
                          schedule.transactionID
                        }`
                      );
                      // navigate to invoice
                    }}
                  />
                );
              })
            )}
          </Box>
        </Box>

        <Box my={3}>
          <Typography variant="body1" fontWeight={600} mb={1}>
            Analisa Bisnis
          </Typography>
          <Box my={1}>
            <DateRangePicker
              startDate={dateRange.startDate}
              endDate={dateRange.endDate}
              onChangeStartDate={(date) => {
                setDateRange((prev) => ({
                  ...prev,
                  startDate: date,
                }));
              }}
              onChangeEndDate={(date) => {
                setDateRange((prev) => ({
                  ...prev,
                  endDate: date,
                }));
              }}
            />
          </Box>
          <Box display="flex" flexDirection="row" gap={1}>
            {/* <Box p={1} border={`1px solid ${COLOR.neutral200}`} borderRadius={2} flex={1}>
              <Typography
                fontWeight={500}
                fontSize={12}
                color={COLOR.neutral500}
                display="flex"
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                gap={2}
              >
                Tingkat Konversi <FaExchangeAlt />
              </Typography>
              <Typography fontWeight={700} fontSize={16}>0%</Typography>
            </Box> */}
            <Box
              p={1}
              border={`1px solid ${COLOR.neutral200}`}
              borderRadius={2}
              flex={1}
            >
              <Typography
                fontWeight={500}
                fontSize={12}
                color={COLOR.neutral500}
                display="flex"
                flexDirection="row"
                justifyContent="start"
                alignItems="center"
                gap={2}
              >
                Total Penghasilan <FaWallet />
              </Typography>
              <Typography fontWeight={700} fontSize={16}>
                {formatCurrency(
                  transactionOverview?.totalTransactionAmount || 0,
                  companyDetail?.currency || "IDR"
                )}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          mt={3}
          py={1}
          width="100%"
          border={`1px solid ${COLOR.neutral200}`}
          borderRadius={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography my={2} fontWeight={600} textAlign="center">
            Jumlah Transaksi Berhasil
          </Typography>
          <BarChart
            sx={{
              width: "100%",
              ".MuiBarLabel-root": {
                fill: "white",
              },
            }}
            height={300}
            dataset={
              totalTransactionPerDateQuery.isLoading || !totalTransactionPerDate
                ? dataset()
                : totalTransactionPerDate.map((data) => ({
                    day: data.date,
                    transactionCount: data.total,
                  }))
            }
            xAxis={[{ scaleType: "band", dataKey: "day" }]}
            borderRadius={10}
            series={[
              {
                dataKey: "transactionCount",
                color: COLOR.primary500,
              },
            ]}
            barLabel="value"
          />
        </Box>

        <Box
          width="100%"
          mt={3}
          py={1}
          border={`1px solid ${COLOR.neutral200}`}
          borderRadius={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          px={2}
        >
          <Typography my={2} fontWeight={600} textAlign="center">
            Jumlah Transaksi Berdasarkan Status
          </Typography>
          <PieChart
            series={[
              {
                data: totalTransactionByStatus?.[0]
                  ? [
                      {
                        label: `Berhasil (${
                          totalTransactionByStatus[0]?.success || 0
                        })`,
                        value: totalTransactionByStatus[0]?.success || 0,
                        color: COLOR.success500,
                      },
                      {
                        label: `Pending (${
                          totalTransactionByStatus[0]?.pending || 0
                        })`,
                        value: totalTransactionByStatus[0]?.pending || 0,
                        color: COLOR.neutral500,
                      },
                      {
                        label: `Gagal (${
                          totalTransactionByStatus[0]?.failed || 0
                        })`,
                        value: totalTransactionByStatus[0]?.failed || 0,
                        color: COLOR.danger500,
                      },
                    ]
                  : datasetPie,
                highlightScope: { fade: "global", highlight: "item" },
                faded: {
                  innerRadius: 30,
                  additionalRadius: -30,
                  color: "gray",
                },
                innerRadius: 45,
              },
            ]}
            slotProps={{
              legend: {
                itemMarkHeight: 10,
                itemMarkWidth: 10,
                labelStyle: {
                  fontSize: 14,
                },
              },
            }}
            height={200}
          />
        </Box>

        <Typography fontWeight={700} mt={3}>
          Produk Terlaris
        </Typography>
        <Box display="flex" flexDirection="column" gap={1} mt={1}>
          {productByTransactionQuery.isLoading
            ? _renderLoading()
            : _renderProductList()}
        </Box>
      </Box>
    </MobileContainer>
  );
};

export default Home;
