import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Typography, useMediaQuery, useTheme } from '@mui/material';
import rehypeRaw from 'rehype-raw';

interface MarkdownRendererPropsI {
  markdown: string;
  customStyles?: {
    [key: string]: React.CSSProperties;
  };
}

const MarkdownRenderer: React.FC<MarkdownRendererPropsI> = ({
  markdown,
  customStyles = {},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const components: { [key: string]: React.FC<{ children: React.ReactNode }> } = {
    // h1: ({ children }) => (
    //   <Typography
    //     variant={isMobile ? 'h4' : 'h2'}
    //     style={customStyles.h1}
    //   >
    //     {children}
    //   </Typography>
    // ),
    p: ({ children }) => (
      <Typography
        variant="body1"
        style={customStyles.p}
      >
        {children}
      </Typography>
    ),
    // li: ({ children }) => (
    //   <Typography
    //     component="li"
    //     variant="body1"
    //     color={"black"}
    //     style={customStyles.li}
    //   >
    //     {children}
    //   </Typography>
    // ),
    // ul: ({ children }) => (
    //   <Typography
    //     component="ul"
    //     style={{ paddingLeft: '20px', ...customStyles.ul }}
    //   >
    //     {children}
    //   </Typography>
    // ),
  };

  return (
    <ReactMarkdown
      components={components}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
    >
      {markdown.trim()}
    </ReactMarkdown>
  );
};

export default MarkdownRenderer;
