import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import MobileContainer from "../../components/Layout/MobileContainer/MobileContainer";
import dayjs, { Dayjs } from "dayjs";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePIcker";
import { Box, Skeleton, Typography } from "@mui/material";
import SingleSelect from "../../components/Forms/SingleSelect/SingleSelect";
import {
  useGetProductReservationCompactList,
  useGetScheduleDetailByProductIdV2,
} from "../../query/queries";
import { useSelector } from "react-redux";
import { RootReducerState } from "../../redux/reducers";
import {
  APIGetProductCompact,
  APIGetScheduleDetailsByProductId,
  ProductScheduleDetail,
} from "../../api/request.types";
import {
  ScheduleAccordion,
  ScheduleAccordionTransactionDetailType,
} from "./components/ScheduleAccordion/ScheduleAccordion";
import { FaPencilAlt } from "react-icons/fa";
import { COLOR } from "../../utils/color";
import { BottomSheet, BottomSheetRef } from "react-spring-bottom-sheet";
import QuantitySelector from "../../components/QuantityInput/QuantityInput";
import CustomButton from "../../components/CustomButton/CustomButton";
import HelperText from "../../components/Forms/HelperText/HelperText";
import {
  useChangeProductQuotaV2,
} from "../../query/mutations";
import isEmpty from "lodash.isempty";
import { enqueueSnackbar } from "notistack";
import { errorLogger } from "../../utils/logger";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_NAME, RoutePath } from "../../utils/constant";

const Schedule: React.FC = () => {
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [openAccordions, setOpenAccordions] = useState<boolean[]>([]);
  const [selectedProductId, setSelectedProductId] = useState<
    string | undefined
  >();
  const [editDetail, setEditDetail] = useState<{
    data: ProductScheduleDetail | null;
    editQty: number;
    openBottomSheet: boolean;
  }>({
    data: null,
    editQty: 0,
    openBottomSheet: false,
  });

  const navigate = useNavigate();
  const location = useLocation();
  const bottomSheetRef = useRef<BottomSheetRef>(null);

  const userReducer = useSelector(
    (state: RootReducerState) => state.userReducer
  );

  const productCompactListQuery = useGetProductReservationCompactList(
    userReducer.data.companyId || ""
  );

  const productList: APIGetProductCompact[] = useMemo(
    () => productCompactListQuery.data?.data.data || [],
    [productCompactListQuery.data?.data.data]
  );

  const changeProductDataMutation = useChangeProductQuotaV2();
  const productListOptions = useMemo(() => {
    return productList.map((productCompact) => ({
      label: productCompact.productName,
      value: productCompact.productID,
    }));
  }, [productList]);

  const scheduleDetailQuery = useGetScheduleDetailByProductIdV2(
    selectedProductId || "",
    selectedDate.format("YYYY-MM-DD")
  );

  const scheduleData: APIGetScheduleDetailsByProductId["scheduleDetails"] =
    useMemo(() => {
      return scheduleDetailQuery.data?.data?.data?.scheduleDetails || [];
    }, [scheduleDetailQuery.data?.data?.data?.scheduleDetails]);

  const updateUrlParams = useCallback(
    (date: Dayjs, productId: string | undefined) => {
      const params = new URLSearchParams();
      if (date) {
        params.set("date", date.format("YYYY-MM-DD"));
      }
      if (productId) {
        params.set("selectedProductId", productId);
      }
      navigate(`${location.pathname}?${params.toString()}`, { replace: true });
    },
    [location.pathname, navigate]
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const dateParam = searchParams.get("date");
    const productIdParam = searchParams.get("selectedProductId");

    if (dateParam && dayjs(dateParam).isValid()) {
      const paramsDate = dayjs(dateParam);
      if (paramsDate.isBefore(dayjs(), "day")) {
        setSelectedDate(dayjs());
      } else {
        setSelectedDate(paramsDate);
      }
    }

    if (productIdParam) {
      setSelectedProductId(productIdParam);
    } else if (
      productCompactListQuery.isFetched &&
      !productCompactListQuery.isLoading &&
      productListOptions.length > 0
    ) {
      setSelectedProductId(productListOptions[0].value);
    }
  }, [
    location.search,
    productCompactListQuery.isFetched,
    productCompactListQuery.isLoading,
    productListOptions,
  ]);

  useEffect(() => {
    if (selectedDate && selectedProductId) {
      updateUrlParams(selectedDate, selectedProductId);
    }
  }, [selectedDate, selectedProductId, updateUrlParams]);

  useEffect(() => {
    if (scheduleData?.length !== openAccordions.length) {
      setOpenAccordions(new Array(scheduleData?.length).fill(false));
    }
  }, [scheduleData?.length, openAccordions.length]);

  const handleToggle = (index: number) => {
    setOpenAccordions((prev) => {
      const newAccordions = [...prev];
      newAccordions[index] = !newAccordions[index];
      return newAccordions;
    });
  };

  const handleDetailClick = (
    detail: ScheduleAccordionTransactionDetailType,
    index: number
  ) => {
    navigate(`${RoutePath[ROUTE_NAME.TRANSACTION]}/${detail.transactionId}`);
  };

  const handleOnClickEdit = (scheduleProductDetail: ProductScheduleDetail) => {
    setEditDetail({
      data: { ...scheduleProductDetail },
      editQty: scheduleProductDetail.remainingQuota,
      openBottomSheet: true,
    });
  };

  const hideBottomSheet = () =>
    setEditDetail((prev) => ({
      ...prev,
      openBottomSheet: !prev.openBottomSheet,
    }));

  const handleOnClickEditQty = async () => {
    if (isEmpty(selectedProductId) && isEmpty(editDetail.data)) return;
    try {
      await changeProductDataMutation.mutateAsync({
        productId: selectedProductId as string,
        date: selectedDate.format("YYYY-MM-DD"),
        startTime: editDetail.data!.startTime,
        endTime: editDetail.data!.endTime,
        quota: editDetail.editQty,
      });
      enqueueSnackbar({ message: "Update Success!", variant: "success" });
      await scheduleDetailQuery.refetch();
      setEditDetail((prev) => ({
        ...prev,
        openBottomSheet: false,
      }));
    } catch (error) {
      errorLogger(error);
      enqueueSnackbar({
        message: "Terjadi kesalahan mohon coba beberapa saat lagi!",
        variant: "error",
      });
    }
  };

  return (
    <MobileContainer>
      <CustomDatePicker
        value={selectedDate}
        onChange={(date: Dayjs) => setSelectedDate(date)}
      />
      <Box p={2} display="flex" flexDirection="column" gap={2} pb={5}>
        {productCompactListQuery.isLoading ? (
          <Skeleton width="100%" height="80px" />
        ) : productListOptions.length === 0 ? (
          <Box> Belum ada Produk </Box>
        ) : (
          <SingleSelect
            optionProps={{
              value: selectedProductId,
              onChange: (selectedOption: string | undefined) => {
                setSelectedProductId(selectedOption);
              },
              options: productListOptions,
              placeholder: "Select Product",
            }}
          />
        )}

        {scheduleDetailQuery.isLoading
          ? new Array(5).fill("").map((e, idx) => (
              <Box key={idx} display="flex" flexDirection="row" gap={1} pt={2}>
                <Skeleton
                  width="100%"
                  height="80px"
                  variant="rounded"
                  sx={{ borderRadius: 2 }}
                />
                <Skeleton
                  width="52px"
                  height="80px"
                  variant="rounded"
                  sx={{ borderRadius: 2 }}
                />
              </Box>
            ))
          : scheduleData.map((scheduleProductDetail, index) => {
              const { startTime, endTime, remainingQuota, transactionDetails } =
                scheduleProductDetail;
              return (
                <Box key={index} display="flex" flexDirection="row" gap={1}>
                  <ScheduleAccordion
                    startTime={startTime}
                    endTime={endTime}
                    remainingQuota={remainingQuota}
                    transactionDetails={transactionDetails.map((e) => ({
                      email: e.email,
                      fullName: e.fullName,
                      itemCount: e.qty,
                      transactionId: e.transactionID,
                    }))}
                    open={openAccordions[index] || false}
                    onToggle={() => handleToggle(index)}
                    onClickDetail={handleDetailClick}
                  />
                  <Box
                    onClick={() => handleOnClickEdit(scheduleProductDetail)}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    border={`1px solid ${COLOR.neutral300}`}
                    p={2}
                    borderRadius={2}
                    overflow="hidden"
                  >
                    <FaPencilAlt size={16} />
                  </Box>
                </Box>
              );
            })}
      </Box>

      <BottomSheet
        ref={bottomSheetRef}
        open={editDetail.openBottomSheet}
        onDismiss={hideBottomSheet}
      >
        <Box p={2} pb={4} display="flex" flexDirection="column" gap={2}>
          <Typography>Ubah Sisa Item</Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" fontSize={16} fontWeight={500}>
                {editDetail.data?.startTime}-{editDetail.data?.endTime} WIB
              </Typography>
              <Typography variant="caption">
                {editDetail.data?.remainingQuota} item tersisa
              </Typography>
            </Box>
            <QuantitySelector
              value={editDetail.editQty}
              onChange={(qty) =>
                setEditDetail((prev) => ({ ...prev, editQty: qty }))
              }
              min={0}
              max={editDetail.data?.remainingQuota}
              maxDigits={5}
            />
          </Box>
          <HelperText
            text={`Catatan: Kamu bebas menambah kan atau mengurangi sisa`}
          />
          <Box display="flex" flexDirection="row" gap={1}>
            <CustomButton
              variant="text-secondary"
              disabled={changeProductDataMutation.isLoading}
              onClick={hideBottomSheet}
            >
              Batal
            </CustomButton>
            <CustomButton
              onClick={handleOnClickEditQty}
              disabled={changeProductDataMutation.isLoading}
            >
              {changeProductDataMutation.isLoading ? "Loading..." : "Ubah"}
            </CustomButton>
          </Box>
        </Box>
      </BottomSheet>
    </MobileContainer>
  );
};

export default Schedule;
