import { useNavigate } from "react-router-dom";
import {
  PRODUCT_TYPE,
  ProductDetailType,
  SocialMediaType,
} from "../../api/request.types";
import {
  FONT_COLOR,
  ROUTE_NAME,
  RoutePath,
  SOCIAL_MEDIA,
  THUMB_STYLE,
} from "../../utils/constant";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Box, CircularProgress, Typography } from "@mui/material";
import ThumbnailSection from "../ThumbnailSection/ThumbnailSection";
import ThumbnailButton from "../ThumbnailButton/ThumbnailButton";
import { CurrencyCodeType } from "../../types/globalTypes";
import useNavigateDynamicPage from "../../hooks/useNavigateDynamicPage";
import NeemaLogoPNG from "../../assets/images/neema-logo.png";

interface TemplateProps {
  mode?: "preview" | "default";
  fontColor?: string;
  color?: string;
  logoSignedURL?: string;
  name?: string;
  description?: string;
  currency?: CurrencyCodeType;
  socialMedia: Partial<SocialMediaType>;
  productDetails?: Array<ProductDetailType>;
}

const TemplateHome = (props: TemplateProps) => {
  const {
    mode = "default",
    fontColor = FONT_COLOR,
    color = "#FFFFFF",
    logoSignedURL = NeemaLogoPNG,
    name = "-",
    description = "-",
    socialMedia,
    productDetails = [],
    currency = "IDR",
  } = props;
  const navigate = useNavigateDynamicPage();
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  const handleOnClickReservation = (product: ProductDetailType) => {
    navigate(RoutePath[ROUTE_NAME.DYNAMIC_CHECKOUT] + `/${product.productID}`, {
      state: {
        data: product,
        selectedDate: selectedDate.format("YYYY-MM-DD"),
      },
    });
  };
  const handleOnClickLink = (link: string) => {
    const newWindow = window.open(link, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  useEffect(() => {
    const handleMessage = (event: MessageEvent<string>) => {
      console.log("Message received:", event.data);
      if (event.data === "reload") {
        window.location.reload();
      }
    };
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const socialMediaKeys = Object.keys(socialMedia! || {})
    .filter(
      (sm) =>
        Object.keys(SOCIAL_MEDIA).includes(sm) &&
        socialMedia?.[sm as keyof SocialMediaType]
    );
  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        height: "100vh",
        overflowY: "scroll",
        color: fontColor,
        backgroundColor: color,

        "@media (min-width: 576px)": mode === 'preview' ? undefined : {
          backgroundImage: "none",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        },

        backgroundImage: `url(${logoSignedURL})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          pb: "100px",
          "@media (min-width: 576px)": mode === 'preview' ? undefined : {
            py: 5,
            gap: 2,
            px: 2,
            maxWidth: "800px",
          },
        }}
      >
        <Box my={2}>
          <Box
            display="flex"
            width="150px"
            sx={{
              aspectRatio: "1",
              boxShadow: 2,
              borderRadius: "50%",
              overflow: "hidden",
            }}
          >
            <img
              alt="product-img"
              className="img-default"
              style={{ objectFit: "cover" }}
              src={logoSignedURL}
            />
          </Box>
        </Box>
        <Box px={2} width="100%">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            sx={{
              my: 2,
              position: "relative",
              overflow: "hidden",
              borderRadius: 3,
              boxShadow: 3,
              width: "100%",
              "@media (min-width: 576px)": mode === 'preview' ? undefined : {
                borderRadius: "initial",
                boxShadow: "initial",
              },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                zIndex: "0",
                opacity: "0.6",
                "@media (min-width: 576px)": mode === 'preview' ? undefined : {
                  display: "none",
                },
              }}
            ></Box>
            <Box px={2} display="flex" flexDirection="column" my={2} zIndex={1}>
              <Typography fontWeight={500} variant="h4" textAlign="center" mb={1}>
                {name || ""}
              </Typography>
              <Typography variant="body1" textAlign="center">
                {description || ""}
              </Typography>
            </Box>
            {(socialMediaKeys.length > 0) && <Box
              display="flex"
              flexWrap="wrap"
              flexDirection="row"
              maxWidth={"550px"}
              px={2}
              gap={2}
              justifyContent="center"
              mb={5}
              zIndex={1}
            >
              {socialMediaKeys.map((smKey, idx) => {
                const socialMediaData =
                  socialMedia?.[smKey as keyof SocialMediaType];
                const Icon = SOCIAL_MEDIA[smKey as keyof SocialMediaType];
                return (
                  <a
                    href={socialMediaData}
                    key={idx}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      pointerEvents: mode === "preview" ? "none" : "initial",
                    }}
                  >
                    <Icon fontSize={24} color={props.fontColor} />
                  </a>
                );
              })}
            </Box>}
          </Box>
        </Box>
        <Box
          width="100%"
          maxWidth="550px"
          px={2}
          display="flex"
          flexDirection="column"
          gap={2}
          alignItems="center"
        >
          {(productDetails || [])
            .filter((product) => product.status === "active")
            .map((product, idx) => {
              return product.type === PRODUCT_TYPE.SECTION ? (
                <ThumbnailSection
                  key={idx}
                  title={product.name}
                  primaryColor={color}
                  baseColor={fontColor}
                />
              ) : (
                <ThumbnailButton
                  key={idx}
                  type={product.thumbStyle as THUMB_STYLE}
                  primaryColor={color}
                  baseColor={fontColor}
                  data={{
                    img: product.thumbImg,
                    title: product.name,
                    subtitle: product.subtitle,
                    buttonText: product.buttonText,
                    // price:product.
                    // discount
                    currency: currency || "IDR",
                    selectedDate,
                  }}
                  disabled={mode !== "default"}
                  onClick={() => {
                    switch (product.type) {
                      case PRODUCT_TYPE.RESERVATION:
                        handleOnClickReservation(product);
                        break;
                      case PRODUCT_TYPE.LINK:
                        handleOnClickLink(product.link || "");
                        break;
                      default:
                        break;
                    }
                  }}
                  onChangeSelectedDate={(date) => {
                    if (date) {
                      setSelectedDate(date);
                      navigate(
                        RoutePath[ROUTE_NAME.DYNAMIC_CHECKOUT] +
                        `/${product.productID}`,
                        {
                          state: {
                            data: product,
                            selectedDate: date.format("YYYY-MM-DD"),
                          },
                        }
                      );
                    }
                  }}
                />
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default TemplateHome;
