import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import { COLOR } from "../../utils/color";

export const EventItemContainerSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: 1,
};

export const EventItemDateSx: SxProps<Theme> = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '56px',
  aspectRatio: 1,
  flexDirection: 'column',
  backgroundColor: COLOR.primary500,
  color: 'white',
  borderRadius: 2,
};

export const EventRightSectionSx: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  flex: 1,
};

export const EventItemDetail: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
};